<template>
  <div>
    <section class="bg-grey bg bg-menu">
      <b-row class="content-wrapper row">
        <b-col
          xl="5"
          md="12"
        >
          <h2 class="mt-4 mb-xl-3 text-xl-left">
            Mi cuenta > <b>Mis datos</b>
          </h2>
        </b-col>
      </b-row>
    </section>

    <section class="bg-grey bg">
      <b-row class="content-wrapper row pt-5">
        <b-col
          lg="12"
        >
          <b-row>
            <b-col
              lg="4"
            >
              <b-card class="px-5">
                <div
                  class="text-center"
                >
                  <b-avatar
                    class="avatar-size"
                    variant="primary"
                  />
                  <h2 class="mt-2">
                    {{ this.$store.state.account.user.name }} <!--todo: Poner el nombre de usuario-->
                  </h2>
                  <p>Distribuidor</p> <!--todo: Poner el nombre de usuario-->
                  <ul class="ul-links">
                    <li>
                      <a
                        href="/profile"
                        class="active"
                        @click.prevent="$router.push({ name: 'profile-information'})"
                      ><b>Mis datos</b></a>
                    </li>
                    <li>
                      <a
                        href="/all-orders"
                        @click.prevent="$router.push({ name: 'all-orders'})"
                      >Mis compras</a>
                    </li>
                    <li>
                      <a
                        href="/profile-rewards"
                        @click.prevent="$router.push({ name: 'profile-rewards'})"
                      >Recompensas</a>
                    </li>
                    <!--                    <li>
                      <a
                        href="/profile"
                        target="_blank"
                      >Favoritos</a>
                    </li>-->
                  </ul>
                </div>
              </b-card>
            </b-col>
            <b-col
              lg="8"
            >
              <b-card>
                <b-row>
                  <b-col xl="12">
                    <h2> Información del usuario</h2>
                  </b-col>

                  <b-col
                    md="6"
                    xl="6"
                    class="mb-1"
                  >

                    <!-- basic -->
                    <b-form-group
                      label="Número de distribuidor"
                      label-for="basicInput"
                    >
                      <b-form-input
                        id="basicInput"
                        v-model="userProfile.distributor_number"
                        placeholder="98840598300"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      label="Nombre"
                      label-for="basicInput"
                    >
                      <b-form-input
                        id="basicInput"
                        v-model="userProfile.name"
                        placeholder="Ana"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    xl="6"
                    class="mb-1"
                  >

                    <!-- basic -->
                    <b-form-group
                      label="Correo electrónico"
                      label-for="basicInput"
                    >
                      <b-form-input
                        id="basicInput"
                        placeholder="correo@usuario.com"
                        disabled
                      />
                    </b-form-group>

                  </b-col>
                </b-row>

                <hr>

                <b-row>
                  <b-col
                    xl="12"
                  >
                    <h2 class="mt-1">
                      Direcciones de envío
                    </h2>
                  </b-col>
                  <b-col xl="12">
                    <a
                      class="pt-1 d-block"
                      href="#"
                      @click.prevent="updateAddress()"
                    >Agregar nueva dirección de envío</a>
                    <b-row>
                      <b-col
                        v-for="address in addresses"
                        :key="address.id"
                        xl="4"
                      >
                        <div class="card-data">
                          <h3>{{ address.name }}</h3>
                          <p>CP: {{ address.postalcode }}</p>
                          <p>{{ address.address }}</p>
                          <strong
                            v-if="address.is_default"
                          >Predeterminada</strong>
                          <a
                            v-else
                            href="#"
                            @click.prevent="toDefaultAddress(address)"
                          >Convertir a predeterminada</a>
                          <br>
                          <a
                            href="#"
                            @click.prevent="updateAddress(address)"
                          >Editar</a>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
    <b-modal
      id="address-edit"
      ref="address-edit"
      title="Dirección"
      hide-footer
      size="lg"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="list-view product-checkout"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-card
            title="Detalles"
          >
            <b-card-body>
              <b-row>

                <!-- Full Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Nombre de contacto"
                    rules="required"
                  >
                    <b-form-group
                      label="Nombre de contacto"
                      label-for="full-name"
                      class="mb-2"
                    >
                      <b-form-input
                        id="full-name"
                        v-model="addressDetails.name"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Mobile Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Teléfono de contacto"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Teléfono de contacto"
                      label-for="mobile-number"
                    >
                      <b-form-input
                        id="mobile-number"
                        v-model="addressDetails.phone"
                        type="number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Address -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Dirección"
                    rules="required"
                  >
                    <b-form-group
                      label="Dirección"
                      label-for="address"
                      class="mb-2"
                    >
                      <b-form-input
                        id="address"
                        v-model="addressDetails.address"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Interior Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Número Interno"
                    rules="integer"
                  >
                    <b-form-group
                      label="Número Interno"
                      label-for="interior_number"
                      class="mb-2"
                    >
                      <b-form-input
                        id="interior_number"
                        v-model="addressDetails.interior_number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Outdoor Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Número Externo"
                    rules="integer|required"
                  >
                    <b-form-group
                      label="Número Externo"
                      label-for="outdoor_number"
                      class="mb-2"
                    >
                      <b-form-input
                        id="outdoor_number"
                        v-model="addressDetails.outdoor_number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- pincode -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Código Postal"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Código Postal"
                      label-for="postalcode"
                      class="mb-2"
                    >
                      <b-form-input
                        id="postalcode"
                        v-model="addressDetails.postalcode"
                        type="number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Suburb -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Colonia"
                    rules="required"
                  >
                    <b-form-group
                      label="Colonia"
                      label-for="suburb"
                      class="mb-2"
                    >
                      <b-form-input
                        id="suburb"
                        v-model="addressDetails.suburb"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- City -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Ciudad"
                    rules="required"
                  >
                    <b-form-group
                      label="Ciudad"
                      label-for="city"
                      class="mb-2"
                    >
                      <b-form-input
                        id="city"
                        v-model="addressDetails.city"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- state -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Estado"
                    rules="required"
                  >
                    <b-form-group
                      label="Estado"
                      label-for="state"
                      class="mb-2"
                    >
                      <b-form-input
                        id="state"
                        v-model="addressDetails.state"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Country -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="country"
                    rules="required"
                  >
                    <b-form-group
                      label="País"
                      label-for="country"
                      class="mb-2"
                    >
                      <v-select
                        id="country"
                        v-model="addressDetails.country_id"
                        :options="countries"
                        :selectable="option => ! option.value.includes('select_value')"
                        label="text"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- References -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Referencias"
                    rules="required"
                  >
                    <b-form-group
                      label="Referencias"
                      label-for="references"
                      class="mb-2"
                    >
                      <b-form-input
                        id="references"
                        v-model="addressDetails.references"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Submit Button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BFormInput,
  BFormGroup, BFormInvalidFeedback, BForm, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
      formAddress: false,
      userProfile: {
        distributor_number: this.$store.state.account.user.distributor_number,
        email: this.$store.state.account.user.email,
        name: this.$store.state.account.user.name,
      },
      addressDetails: {
        name: '',
        phone: '',
        interior_number: '',
        outdoor_number: '',
        postalcode: '',
        city: '',
        state: '',
        address: '',
        references: '',
        suburb: '',
        country_id: '',
      },
      country: {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia, Plurinational State of',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, the Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte d\'Ivoire\'',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran, Islamic Republic of',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea, Democratic People\'s Republic of\'',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic\'',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia, the Former Yugoslav Republic of',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia, Federated States of',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela, Bolivarian Republic of',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
    }
  },
  computed: {
    addresses() {
      return store.state.address.addresses
    },
    countries() {
      const countries = []
      Object.keys(this.country).forEach(key => {
        countries.push({
          value: key,
          text: this.country[key],
        })
      })
      return countries
    },
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  mounted() {
    this.fetchAddresses()
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    fetchAddresses() {
      store.dispatch('address/index')
    },
    updateAddress(address) {
      if (address) {
        this.isCreated = false
        this.addressDetails = address
      } else {
        this.isCreated = true
        this.addressDetails = {
          name: '',
          phone: '',
          interior_number: '',
          outdoor_number: '',
          postalcode: '',
          city: '',
          state: '',
          address: '',
          references: '',
          suburb: '',
          country_id: '',
        }
      }
      this.$refs['address-edit'].show()
    },
    onSubmit() {
      const self = this
      const type = this.isCreated ? 'address/store' : 'address/update'
      store.dispatch(type, { params: this.addressDetails, id: this.addressDetails.id }).then(
        () => {
          self.fetchAddresses()
          this.$refs['address-edit'].hide()
        },
        error => {
          console.log(error)
          return Promise.reject(error)
        },
      )
    },
    toDefaultAddress(address) {
      const self = this
      const addressVal = address
      addressVal.is_default = true
      store.dispatch('address/update', { params: addressVal, id: addressVal.id, withDefault: true }).then(
        () => {
          self.fetchAddresses()
          this.$refs['address-edit'].hide()
        },
        error => {
          console.log(error)
          return Promise.reject(error)
        },
      )
    },
    getValidationState({
      dirty,
      validated,
      required: fieldRequired,
      changed,
      valid = null,
    }) {
      const result = dirty || validated ? valid : null
      return !fieldRequired && !changed ? null : result
    },
  },
}

</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

//New Arribals
.card-data{
  margin-top: 10px;
  background-color: #f2f2f2;
  padding: 20px;
  border: 1px solid #979593;
  border-radius: 5px;
}
.bg-menu {
  margin-top: -45px;
  background-color: #fff!important;
}
.header-navbar-shadow{
  background:none!important;
}
//New Arribals
.avatar-size{
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0 auto;
}
.dark-layout{
  .card-data{
    background-color: $theme-dark-body-bg;
  }
  .ul-links{
    a{
      color: $theme-dark-body-color;
    }
  }
  .bg-menu{
    background-color: $theme-dark-card-bg!important;
  }
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-body-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.bg-grey{
  background-color: #f2f2f2;
}.bg-grey{
   background-color: #f2f2f2;
 }

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.ul-links{
  list-style: none;
  text-align: left;
  a{
    color: $dark;
  }
  .active{
    color: $primary;
  }
}
</style>
